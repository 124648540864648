<template>
<v-container>
  <v-row> 
		<v-spacer></v-spacer>
		<v-menu
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
						class="pr-4"
        >
					<v-icon small>mdi-help-circle</v-icon>
          <span>图例说明</span>
        </div>
      </template>

      <v-list dense>
        <v-list-item
          v-for="item in ganttHint"
        >
          <v-list-item-title>
						<div style="width:80px; height:25px; text-align:center;" :style="{backgroundColor:item.color}">
							{{ item.label }}
						</div></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
	</v-row>
  <v-row>
    <!-- @tasks-changed="tasksUpdate" @options-changed="optionsUpdate" @dynamic-style-changed="styleUpdate"  -->
    <gantt-elastic :options="options" :tasks="allTasks" :dynamic-style="dynamicStyle">
      <gantt-header slot="header" :options="options"> </gantt-header>
    </gantt-elastic>
  </v-row>
  <TaskEdit ref="rightTaskEditor" />
</v-container>
</template>

<style></style>

<script>
import GanttElastic from "gantt-elastic";
import GanttHeader from "gantt-elastic-header";
import dayjs from "dayjs";
import {
  mapGetters,
  mapActions
} from "vuex";
import {
  constant
} from "@/constants/constant.js";


var gVm = {
  name: "Gantt",
  components: {
    GanttElastic,
    GanttHeader,
    TaskEdit: () => import("@/components/common/taskEdit/TaskEdit.vue"),
  },
  props: ["item"],
  data() {
    return {
			milestones:[],
			ganttHint:[],
      dynamicStyle: {
        "task-list-header-label": {
          "font-weight": "bold",
        },
      },
      options: {
        taskMapping: {
          progress: "percent",
        },
        maxRows: 100,
        maxHeight: 450,
        title: {
          label: "甘特图",
          html: true,
        },
        row: {
          height: 10,
        },
        calendar: {
          hour: {
            display: true,
          },
        },
        chart: {
          progress: {
            bar: false,
          },
          expander: {
            display: true,
          },
        },
        taskList: {
          expander: {
            straight: true,
          },
          columns: [{
              id: 2,
              label: "描述",
              value: "label",
              width: 250,
              expander: true,
              html: true,
              events: {
                click({
                  data,
                  column
                }) {
                  if (data.type == "task") {

                    gVm.methods.selectTask(data.taskObj);
                  }
                },
              },
            },
            {
              id: 3,
              label: "负责人",
              value: "owner",
              width: 80,
              html: true,
            },
            {
              id: 3,
              label: "日期",
              value: (task) =>
                task.start ?
                dayjs(task.start).format("YYYY-MM-DD") +
                "~" +
                dayjs(task.end).format("YYYY-MM-DD") :
                "",
              width: 150,
            },
            {
              id: 6,
              label: "完成度",
              // value: "task_progress_status",
              value: (task) =>
                task.task_progress_status == "0/0" ?
                "" :
                task.task_progress_status,
              width: 70,
              html: true,
            },
            {
              id: 7,
              label: "评分",
              value: "task_confidence",
              width: 70,
              html: true,
            },
          ],
        },
        locale: {
          code: "zh",
          Now: "今天​​",
          "X-Scale": "缩放日期",
          "Y-Scale": "缩放高度",
          "Task list width": "任务列表宽度",
          "Before/After": "扩张",
          "Display task list": "显示任务列表",
          months: "一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月".split(
            "_"
          ),
          weekdays: "星期日_星期一_星期二_星期三_星期四_星期五_星期六".split(
            "_"
          ),
        },
      },
    };
  },
	created(){
		this.updateData();
		this.ganttHint = constant.ganttColor;
	},
  mounted() {
  },
  computed: {
    ...mapGetters("auth", ["authUser"]),
    ...mapGetters("milestone", ["defaultMilestone"]),
    allTasks() {
      let tasks = [];
      let allCnt = 0,
        completedCnt = 0;
      if (this.milestones) {
        this.milestones.map((ms) => {
          let alltask = 0,
            comtask = 0;
          ms.ms_tasks.map((task) => {
            allCnt++;
            alltask++;
            if (task.task_status == 1) {
              completedCnt++;
              comtask++;
            }
            tasks = [...tasks, ...this.genTaskList(ms.ms_id, task)];
          });

          let itemStart = new Date(ms.ms_start_date);
          let itemEnd = new Date(ms.ms_end_date);
          let msObj = {
            id: ms.ms_id,
            parentId: 0,
            label: "<span style='cursor:pointer;'> 里程碑:" +
              ms.ms_name +
              "</span>",
            owner: this.$options.filters.username(ms.ms_owner),
            start: itemStart.getTime(),
            end: itemEnd.getTime(),
            duration: Math.max(
              itemEnd.getTime() - itemStart.getTime(),
              10 * 60 * 60 * 1000
            ),
            percent: ms.ms_progress,
            task_progress_status: comtask + "/" + alltask,
            task_confidence: "-",
            taskObj: ms,
            type: "milestone",
            collapsed: false,
          };
          tasks.push(msObj);
        });
      }

      // if (this.defaultMilestone) {
      //   let alltask = 0,
      //     comtask = 0;
      //   let ms = this.defaultMilestone;

      //   ms.ms_tasks.map((task) => {
      //     allCnt++;
      //     alltask++;
      //     if (task.task_status == 1) {
      //       completedCnt++;
      //       comtask++;
      //     }
      //     tasks = [...tasks, ...this.genTaskList(1001, task)];
      //   });
      //   let itemStart = new Date(this.item.item_start_date);
      //   let itemEnd = new Date(this.item.item_end_date);

      //   let msObj = {
      //     id: 1001,
      //     parentId: 0,
      //     label: "里程碑:" + "未设置里程碑",
      //     owner: "",
      //     start: itemStart.getTime(),
      //     end: itemStart.getTime(),
      //     duration: 0,
      //     percent: ms.ms_progress,
      //     task_progress_status: comtask + "/" + alltask,
      //     task_confidence: "-",
      //     taskObj: null,
      //     type: "milestone",
      //     collapsed: false,
      //   };
      //   tasks.push(msObj);
      // }

      if (this.item ) {
        let itemStart = new Date(this.item.item_start_date);
        let itemEnd = new Date(this.item.item_end_date);

        let root = {
          id: 0,
          label: "<span style='cursor:pointer;' @click='onTask'>项目:" +
            this.item.item_name +
            "</span>",
          owner: this.$options.filters.username(this.item.item_owner),
          start: itemStart.getTime(),
          end: itemEnd.getTime(),
          duration: itemEnd.getTime() - itemStart.getTime(),
          percent: this.item.item_progress,
          taskObj: null,
          task_progress_status: completedCnt + "/" + allCnt,
          task_confidence: "-",
          type: "project",
          collapsed: false,
        };
        tasks.push(root);
      }

      return tasks;
    },
  },
  methods: {
    ...mapActions("milestone", ["getMilestones"]),
    updateData() {
      this.getMilestones({
        ms_parent_item: this.item.item_id,
      }).then((res) => {
				if(res){
					this.milestones = res;
				}
      });
    },
    genTaskList(pId, task) {
      let arr = [];
      let itemStart = new Date(task.task_start_date);
      let itemEnd = new Date(task.task_end_date);
			let now = new Date();
      let alltask = 0,
        comtask = 0;

			let gColor = constant.ganttColor[0].color;
			if(itemEnd<now){
				gColor = constant.ganttColor[2].color;
			}
			if(task.task_status ==1){
				gColor = constant.ganttColor[1].color;
			}
			if(task.task_status ==3){
				gColor = constant.ganttColor[3].color;
			}
			if(task.task_status ==4){
				gColor = constant.ganttColor[5].color;
			}
			if(task.task_status ==5){
				gColor = constant.ganttColor[4].color;
			}

      task.task_tasks.map((e) => {
        alltask++;
        if (e.task_status == 1) {
          comtask++;
        }
        arr = [...this.genTaskList(task.task_id, e)];
      });

      let taskObj = {
        id: task.task_id,
        parentId: pId,
        label: "<span style='cursor:pointer;'>" +
          task.task_name +
          "</span>",
        owner: this.$options.filters.username(task.task_owner),
        start: itemStart.getTime(),
        end: itemEnd.getTime(),
        duration: Math.max(
          itemEnd.getTime() - itemStart.getTime(),
          10 * 60 * 60 * 1000
        ),
        percent: task.task_progress,
        task_progress_status: comtask + "/" + alltask,
        task_confidence: "-",
        taskObj: task,
        type: "task",
        collapsed: false,
        style: {
          base: {
            fill: gColor,
            stroke: "#0077C0",
          },
        },
      };
      arr.push(taskObj);

      return arr;
    },
    test() {

    },
    selectTask(e) {

      // this.$refs.rightTaskEditor.openTaskEditor(e)
    },
		onTask(){

		}
    // tasksUpdate(tasks) {
    //   this.tasks = tasks;
    // },
    // optionsUpdate(options) {
    //   this.options = options;
    // },
    // styleUpdate(style) {
    //   this.dynamicStyle = style;
    // }
  },
};

export default gVm;
</script>
